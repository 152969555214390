export const environment = {
    production: true,
    api: 'https://staging.api.classichub.co.nz',
    buildVersion: '2024.11.13.5',
    uri: 'https://staging.classichub.co.nz',
    envName: 'staging',
    isTest: true,

};

